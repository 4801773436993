import { useState } from "react";
import React from "react";
import { NFPQuery } from "../../prismic/staticQueries/NFPQuery/index.js"
import { Accordion } from "react-bootstrap";
import Layout from "../../layout";
//import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import banner_image_top from "../../images/siteImages/home/demo-banner-bg.png";
import banner_image from "../../images/siteImages/home/bg-nfp.png";
import nfp_aquarium from "../../images/siteImages/dubai-images/dubai-aquarium.png";
import rox_like from "../../images/siteImages/rox-like.svg";
import rox_share from "../../images/siteImages/rox-share.svg";
import rox_predict from "../../images/siteImages/rox-predict.svg";
import nfp_burj_khalifa from "../../images/siteImages/dubai-images/dubai-burj-khalifa.png";
import nfp_dolphinarium from "../../images/siteImages/dubai-images/dubai-dolphinarium.png";
import nfp_fountain from "../../images/siteImages/dubai-images/dubai-fountain.png";
import nfp_miracle from "../../images/siteImages/dubai-images/dubai-miracle.png";
import nfp_museum_future from "../../images/siteImages/dubai-images/dubai-museum-future.png";
import nfp_right_image from "../../images/siteImages/home/fb-contest.png";
import nfp_left_image from "../../images/siteImages/home/nfp.jpg";
import NFPCountDown from "./NFPCountDown.js";
import PaymentSlider from "../Company/PaymentSlider.js";
import NFPBanner from "./NFPBanner.js";
import NfpFbContest from "./NfpFbContest.js";
//const targetDate = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
// const targetDate = new Date('2024-08-15T23:59:00').getTime();

const targetDate = new Date('2024-08-31T00:00:00').getTime();

const NFP = () => {
  const language = useSelector((state) => state.language);
  const NFPData = NFPQuery(language);
  const [loadAllTerms, setLoadAllTerms] = useState(false);

  console.log("language", NFPData);
  const {
    nfp_heading,
    nfp_top_heading,
    nfp_top_heading1,
    nfp_top_description,
    nfp_top_description1,
    nfp_top_description2,
    four_box,
    three_box,
    dubaiimages_box,
    terms_box,
    key_figures_boxes,
    questionsbox
  } = NFPData;

  const four_icon = [
    rox_like,
    rox_share,
    rox_predict
  ];

  const dubaiimages_icon = [
    nfp_aquarium,
    nfp_burj_khalifa,
    nfp_dolphinarium,
    nfp_fountain,
    nfp_miracle,
    nfp_museum_future
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      {/* <BreadCrumbBanner banner_image={banner_image} /> */}
      <NFPBanner demo_banner_image={banner_image_top}/>
      {/* <section className="gradient-bg">
        <Container className="">
          <Row className="py-3">
            {three_box.length > 0 && three_box.map((threemap, threemapInd) => (
              <Col className="three-card"
                lg={4}
                md={4} key={"threemapInd" + threemapInd}>
                <div className="h-100">
                  <div className="d-flex flex-column align-items-center">
                    <h4 className="mb-0"> {threemap.three_heading.text}</h4>
                    <p> {threemap.three_description.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      */}
      <section>
      <div class="gradient-bg py-3 rotate-4">
<marquee>
<div class="d-flex gap-4 align-items-center text-white">
<div>•</div>
<div class="winner-announce text-uppercase">12th March 2024</div>
<p class="mb-0 text-uppercase text-white fw-semibold">Result announcement</p>
<div>•</div>
<div class="winner-announce text-uppercase">12th March 2024</div>
<p class="mb-0 text-uppercase text-white fw-semibold">Result announcement</p>
<div>•</div>
<div class="winner-announce text-uppercase">12th March 2024</div>
<p class="mb-0 text-uppercase text-white fw-semibold">Result announcement</p>
<div>•</div>
<div class="winner-announce text-uppercase">12th March 2024</div>
<p class="mb-0 text-uppercase text-white fw-semibold">Result announcement</p>
</div>
</marquee>
</div>
      </section>
      <section className="sectionbg">
        <Container className="">
          <Row className="pt-5">
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <h4 className="text-bold-md text-black">{nfp_top_heading.text}</h4>
              {/* <strong className="text-bold-md text-black">4 Night and 5 Days</strong> */}
              <p className="text-gray my-3">
                {nfp_top_description.text}</p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={4} className="py-5 d-flex align-items-center">
              <img alt="ddd" src={nfp_right_image} className="dubai-img" />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col lg={4} className="py-5 d-flex align-items-center">
              <img alt="ddd" src={nfp_left_image} className="dubai-img" />
            </Col>
            <Col lg={1}></Col>
            <Col lg={6} className="d-flex flex-column justify-content-center">
            <div className="accordion-styles accordion--one">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="accordion accordion_one p-0" id="accordion_one">
                    <div className="accordion-single">
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        {questionsbox.map((item, index) => (
                          <Accordion.Item key={`acc-${index}`} eventKey={index}>
                            <Accordion.Header>
                              {item.question.text}
                            </Accordion.Header>
                            <Accordion.Body>{item.answer.text}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sectionbg py-5">
        <Container>
          <Row>
          <Col lg={12} md={12}>
              <h2 className="my-5 text-bold-md text-black text-center">Contest Winner</h2>
            </Col>
          <Col lg={12} md={12}>
            <NfpFbContest/>
          </Col>
          </Row>
          </Container>
          </section>
      <section className="sectionbg py-5">
        <Container>
          <Row>
          <Col lg={12} md={12}>
              <h2 className="my-5 text-bold-md text-black text-center">{nfp_top_heading1.text}</h2>
            </Col>
            <Col md="12">
              <NFPCountDown  targetDate={targetDate} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sectionbg">
        <Container className="">
          <Row className="pb-5">
            <Col lg={12} md={12}>
              <h2 className="my-5 text-bold-md text-black text-center">{nfp_heading.text}</h2>
            </Col>
            {four_box.length > 0 && four_box.map((fourmap, fourmapInd) => (
              <Col className="mb-5 d-flex justify-content-center"
                lg={4}
                md={6} key={"fourmapInd" + fourmapInd}>
                <div className="four-card mb-4 h-100">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-4">
                      <img alt="ddd" src={four_icon[fourmapInd]} className="icon-width" style={{ width: "70px" }} />
                    </div>
                    <p> {fourmap.four_description.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="sectionbg p-top-10 pb-5 p-bottom-80 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h2 className="pt-5 my-3 text-bold-md text-black">Terms and Conditions</h2>
            </Col>
            <Col lg={12}>
              <ul>
                {terms_box.slice(0, loadAllTerms ? terms_box.length : 5).map((reason, reasonInd) => (
                  <li className="text-gray my-3" key={"reasonInd" + reasonInd}>
                    {reason.DemoTerms_content.text}
                  </li>
                ))}
              </ul>
              {!loadAllTerms && (
                <div className="text-center pt-3">
                  <button
                    className="btn btn-primary p-3" style={{ lineHeight: "0", borderRadius: "50%" }}
                    onClick={() => setLoadAllTerms(true)}
                  >
                    <i class="fa fa-plus-circle" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                  </button>
                </div>
              )}
            </Col>
            {/* <Col lg={12}>
              <ul>
                {terms_box.map((reason, reasonInd) => (
                  <li className="text-gray my-3" key={"reasonInd" + reasonInd}>
                    {reason.DemoTerms_content.text}
                  </li>
                ))}

              </ul>
            </Col> */}
          </Row>
        </Container>
      </section>
      {/* <section className="sectionbg">
        <Container className="">
          <Row className="py-5">
            {dubaiimages_box.length > 0 && dubaiimages_box.map((dubaiimagesmap, dubaiimagesmapInd) => (
              <Col md={2} lg={2} sm={2} key={"dubaiimagesInd" + dubaiimagesmapInd}>
                <div class="image bg-yellow">
                  <img class="slider-img" src={dubaiimages_icon[dubaiimagesmapInd]} alt="Rasm" />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section> */}
      <PaymentSlider/>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(NFP);